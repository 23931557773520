<!-- 人格报告倾向 mobile -->
<template
  ><div class="main">
    <div class="print-page" id="cover" name="cover">
      <h1 class="lj-text-center">人格倾向报告</h1>
      <h2>性格评测结果</h2>
      <p>
        性格内向、情感被动的人很难从销售、采访这样与人打交道的工作中获得快乐，自然也难有所进步。合适的专业不仅要适应兴趣，同时也要与性格对接。为了做出更科学的抉择，性格分析也将归纳在评测之内。（如下图）
      </p>
      <table class="result">
        <thead>
          <th>您的基本性格类型为 ：{{ mbti_type }}</th>
          <th>比例(ESTJ)</th>
          <th>比例(INFP)</th>
        </thead>
        <tbody>
          <tr>
            <td>E（外向）-I（内向）</td>
            <td>{{ mbti_e }}</td>
            <td>{{ mbti_i }}</td>
          </tr>
          <tr>
            <td>S（实感）-N（直觉）</td>
            <td>{{ mbti_s }}</td>
            <td>{{ mbti_n }}</td>
          </tr>
          <tr>
            <td>T（思考）-F（情感）</td>
            <td>{{ mbti_t }}</td>
            <td>{{ mbti_f }}</td>
          </tr>
          <tr>
            <td>J（判断）-P（认知）</td>
            <td>{{ mbti_j }}</td>
            <td>{{ mbti_p }}</td>
          </tr>
          <tr>
            <td>
              <small style="color:blue;">(拓展类型)</small
              >TZ（自我实现/挑战）-AQ（安全稳定）
            </td>
            <td>{{ mbti_tz }}</td>
            <td>{{ mbti_aq }}</td>
          </tr>
        </tbody>
      </table>
      <div id="mbtiContainer">
        <highcharts :options="chartOptions"></highcharts>
      </div>
      <p>
        MBTI-四个维度如同四把标尺，每个人的性格都会落在标尺的某个点上，这个点靠近那个端点，就意味着个体就有哪方面的偏好。如在第一维度上，个体的性格靠近外倾这一端，就偏外倾，而且越接近端点，偏好越强。
      </p>
      <h2>对{{ mbti_type }}类型的分析</h2>
      <div class="main-part">
        <div class="part-title">对{{ mbti_type }}类型的分析</div>
        <div class="part-content mbti-describe">
          <div v-if="mbti_type == 'ISTJ'">
            <p>
              1.严肃、安静、藉由集中心 志与全力投入、及可被信赖获致成功。
            </p>
            <p>2.行事务实、有序、实际 、 逻辑、真实及可信赖</p>
            <p>
              3.十分留意且乐于任何事（工作、居家、生活）均有良好组织及有序。
            </p>
            <p>4.负责任。</p>
            <p>5.照设定成效来作出决策且不畏阻挠与闲言会坚定为之。</p>
            <p>6.重视传统与忠诚。</p>
            <p>7.传统性的思考者或经理。</p>
            <p>
              （1）领导模式：以事实和经验做决定 建立可靠、稳定、持续的工作绩效
              尊重传统和等级制度 奖励遵循规则完成任务的员工
              关注组织的即时性和实际性需要 倾向性顺序：①感觉 ②思维 ③情感 ④直觉
            </p>
            <p>
              （2）
              解决问题模式：喜欢完全依据事实在逻辑框架里进行分析，为获得理想结果，需考虑对人们的影响，然后寻找更多的可能性和其它含义。
            </p>
            <p>工作环境倾向性：</p>
            <p>①喜欢与现实、工作努力、关注事实和结果的人共事</p>
            <p>②能长期提供安全性的环境</p>
            <p>③ 奖励稳步发展和按期完成任务的环境</p>
            <p>④使用系统性工作方法的环境</p>
            <p>⑤任务型定向和鼓励坚定意志的环境</p>
            <p>⑥提供安静、整齐设施的环境</p>
            <p>⑦环境中允许有不被打扰工作的个人空间</p>
            <p>潜在的缺点：</p>
            <p>①因受惠于日常工作而忽视具有长远意义的目标</p>
            <p>②可能忽视人际交往的细节</p>
            <p>③工作方法刻板、不灵活，对变革较少开放态度</p>
            <p>④期望他人和自己一样，同样注意细节和服从管理程序</p>
            <p>发展建议：</p>
            <p>①除了关注现实问题，需关注更深远的、定向于未来的问题</p>
            <p>②需考虑人的因素，向他人表达其应得的赞赏</p>
            <p>③为避免陈规，尝试寻找新的选择</p>
            <p>
              ④需培养耐心，应付那些需要用不同方式沟通或忽视规则和程 序的人
            </p>
            <p>
              适合领域：工商业领域、政府机构 金融银行业、政府机构 技术领域
              医务领域
            </p>
            <p>
              适合职业：审计师、会计、财务经理、办公室行政管理、后勤和供应管理、中层经理、公务（法律、税务）执行人员等银行信贷员、成本估价师、保险精算师、税务经纪人、税务检查员等；机械、电气工程师、计算机程序员、数据库管理员、地质、气象学家、法律研究者、律师等；外科医生、药剂师、实验室技术人员、牙科医生、医学研究员等
            </p>
          </div>
          <div v-else-if="mbti_type == 'ISFJ'">
            <p>1.安静、和善、负责任且有良心。</p>
            <p>2.行事尽责投入。</p>
            <p>3.安定性高，常居项目工作或团体之安定力量。</p>
            <p>4.愿投入、吃苦及力求精确。</p>
            <p>5.兴趣通常不在于科技方面。对细节事务有耐心。</p>
            <p>6.忠诚、考虑周到、知性且会关切他人感受。</p>
            <p>7.致力于创构有序及和谐的工作与家庭环境。</p>
            <p>（1）领导模式：</p>
            <p>①开始可能不愿担任领导，但当需要承担领导任务时会接受</p>
            <p>②希望自己和他人服从组织的需要</p>
            <p>③以个人的影响力作为后盾</p>
            <p>④认真遵守传统程序和规则</p>
            <p>⑤观察细节以获得现实的结果</p>
            <p>（2）学习模式：</p>
            <p>①花费充足的时间以结构化的方式，安静地记忆材料</p>
            <p>
              ②注重实用性，关注做什么可以为人们提供帮助 倾向性顺序： ①感觉 ②情感
              ③思维 ④直觉
            </p>
            <p>
              （3）解决问题模式：喜欢完全依据事实，尤其是当应用于人和准则方面时为获得理想结果，需退一步思考问题的逻辑，然后寻找更多的可能性和其它含义
            </p>
            <p>工作环境倾向性：</p>
            <p>①喜欢与认真、从事组织性任务的人共事</p>
            <p>②能提供安全性和预测性的工作环境</p>
            <p>③组织结构明晰的环境</p>
            <p>④能保持安静、有一些个人空间的环境</p>
            <p>⑤做事坚持到底的环境</p>
            <p>⑥充满个人化、友好、体谅氛围的环境</p>
            <p>⑦服务型定向的环境</p>
            <p>潜在的缺点：</p>
            <p>①过于谨慎小心，尤其是对待未来发展</p>
            <p>②向他人表明自己观点时，显得意志不太坚定</p>
            <p>③因安静、忘却自我的特性而低估自己</p>
            <p>④过度依赖自己的经验，不能根据环境和其它需要灵活调整</p>
            <p>发展建议：</p>
            <p>①工作中需要估计风险，以积极、全面的观点看待未来</p>
            <p>②需发展更多的自信和直率</p>
            <p>③学会宣扬自己的成就</p>
            <p>④对其它形式的做事方式需保持开放态度</p>
            <p>适合领域：无明显领域特征 医护领域 消费类商业、服务业领域</p>
            <p>
              适合职业：行政管理人员、总经理助理、秘书、人事管理者、项目经理、物流经理、律师助手等；外科医生及其它各类医生、家庭医生、牙科医生、护士、药剂师、医学专家、营养学专家、顾问等；零售店、精品店业主、大型商场、酒店管理人员、室内设计师等。
            </p>
          </div>
          <!-- <div v-else-if="mbti_type=='ISFJ'">
                                <p>1.因为坚忍、创意及必须达成的意图而能成功。</p>
                                <p>2.会在工作中投注最大的努力。</p>
                                <p>3.默默强力的、诚挚的及用心的关切他人。</p>
                                <p>4.因坚守原则而受敬重。</p>
                                <p>5.提出造福大众利益的明确远景而为人所尊敬与追随。</p>
                                <p>6.追求创见、关系及物质财物的意义及关联。</p>
                                <p>7.想了解什么能激励别人及对他人具洞察力。</p>
                                <p>8.光明正大且坚信其价值观。</p>
                                <p>9.有组织且果断地履行其愿景。</p>
                                <p>（1）领导模式：</p>
                                <p>①以使个体和组织获得最大利益的远见从事领导工作</p>
                                <p>②从合作中获益而非需要合作的形式</p>
                                <p>③采取平稳、认真、持续性的行为过程实现战略目标</p>
                                <p>④通过工作实现自己的理想和抱负</p>
                                <p>⑤意志坚定地激发他人实现他们的理想</p>
                                <p>（2）学习模式：</p>
                                <p>①极具个体化和思考式的学习方式</p>
                                <p>②强调复杂、结构性的概念和关系的学习 倾向性顺序： ①直觉 ②情感 ③思考 ④感觉</p>
                                <p>（3）解决问题模式：</p>
                                <p>①喜欢识别自己内在观点的可能性，尤其是与人和社会准则有关的问题</p>
                                <p>②为成功实现目标，对定向未来的远见卓识的客观性和现实的细枝末节的问题同样重视</p>
                                <p>工作环境倾向性：</p>
                                <p>①工作中有致力于把为人类创造未来作为理想的人</p>
                                <p>②有表现创造性和展示自我价值机会的环境</p>
                                <p>③环境中鼓励营造和谐气氛和体谅他人</p>
                                <p>④有尊重他人需要的顺畅的管理机制</p>
                                <p>⑤奖励个体的远见卓识</p>
                                <p>⑥提供进行安静思考的时间和空间的工作环境</p>
                                <p>⑦有组织有计划的工作环境</p>
                                <p>潜在的缺点：</p>
                                <p>①发现自己的远见被忽视和低估</p>
                                <p>②面对批评不太坦率</p>
                                <p>③因不太愿强迫别人而过度保守</p>
                                <p>④仅从单一维度考虑他们认为对将来最有益的事</p>
                                <p>发展建议：</p>
                                <p>①在提出自己的观点时，需发展政治领悟力和自主性的技策</p>
                                <p>②需学会及时给他人建设性的反馈</p>
                                <p>③需要不断征求他人的建议和获得他人反馈</p>
                                <p>④需要以更放松和开放的态度面对现状</p>
                                <p>适合领域：咨询、教育、科研等领域；文化、艺术、设计等领域</p>
                                <p>适合职业：心理咨询工作者、心理诊疗师、职业指导顾问、大学教师（人文学科、艺术类）、心理</p>
                            </div> -->
          <div v-else-if="mbti_type == 'INTJ'">
            <p>1.具强大动力与本意来达成目的与创意—固执顽固者。</p>
            <p>2.有宏大的愿景且能快速在众多外界事件中找出有意义的模范。</p>
            <p>3.对所承负职务，具良好能力于策划工作并完成。</p>
            <p>
              4.具怀疑心、挑剔性、独立性、果决，对专业水准及绩效要求高。
            </p>
            <p>（1）领导模式：</p>
            <p>①促使自己和他人完成组织目标</p>
            <p>②坚定有力地贯彻执行组织的理念</p>
            <p>③要求自己和他人具有顽强意志</p>
            <p>④构思、创造新的模型</p>
            <p>⑤必要时，意志坚定地重建整个组织系统</p>
            <p>（2）学习模式：</p>
            <p>①个性化、思考式学习方式，深入其感兴趣的领域</p>
            <p>
              ②智慧型、理论型学习方式，首先要提供一个宏伟蓝图 倾向性顺序：①直觉
              ②思维 ③情感 ④感觉
            </p>
            <p>（3）解决问题模式：</p>
            <p>
              ①喜欢以其内在的认识制定战略、系统和结构，然后客观地做出决定
            </p>
            <p>
              ②为获得最佳结果，会接纳他人和那些使自己的认识更加接近现实的细节资料
            </p>
            <p>工作环境倾向性：</p>
            <p>①喜欢工作中有果断、理智接受挑战、致力于完成远期理念的人</p>
            <p>②工作环境允许思考的独立性和个体性 ③强调效率的环境</p>
            <p>④环境里有具有竞争力和创造性的人</p>
            <p>⑤鼓励和支持自主性的环境</p>
            <p>⑥提供创造机会的环境</p>
            <p>⑦任务定向型和重视详尽思考的环境</p>
            <p>潜在的缺点：</p>
            <p>①可能显得强硬，他人不敢接近</p>
            <p>
              ②长时间不告诉他人自己的想法，因为认为他人也和自己一样认同自己的想法
            </p>
            <p>③可能很难实际操作理想化的想法</p>
            <p>④过度关注任务而忽视他人的贡献</p>
            <p>发展建议：</p>
            <p>①自己的个性化方式和想法可以征求他人的反馈和建议</p>
            <p>②与参与任务的人早一些沟通和讨论自己的想法和战略计划</p>
            <p>③当事实资料不支持自己的想法时，应面对现实</p>
            <p>④明确他人的贡献应受到鼓励和承认的</p>
            <p>
              适合领域：科研、科技应用 技术咨询、管理咨询 金融、投资领域
              创造性行业
            </p>
            <p>
              适合职业：各类科学家、研究所研究人员、设计工程师、系统分析员、计算机程序师、研究开发部经理等
              各类技术顾问、技术专家、企业管理顾问、投资专家、法律顾问、医学专家、精神分析学家等
              经济学家、投资银行研究员、证券投资和金融分析员、投资银行家、财务计划人、企业并购专家等；各类发明家、建筑师、社论作家、设计师、艺术家等。
            </p>
          </div>
          <div v-else-if="mbti_type == 'ISTP'">
            <p>
              1.冷静旁观者—安静、预留余地、弹性及会以无偏见的好奇心与未预期原始的幽默观察与分析。
            </p>
            <p>
              2.有兴趣于探索原因及效果，技术事件是为何及如何运作且使用逻辑的原理组构事实、重视效能。
            </p>
            <p>3.擅长于掌握问题核心及找出解决方式。</p>
            <p>4.分析成事的缘由且能实时由大量资料中找出实际问题的核心。</p>
            <p>(1）领导模式：</p>
            <p>①以身作则</p>
            <p>②一视同仁，尊重每个人的价值</p>
            <p>③面临麻烦时，采用最有利的方法做出快速反应</p>
            <p>④宽松管理员工，喜欢最少监督自己</p>
            <p>⑤采用明晰、理性的管理原则</p>
            <p>(2）学习模式：</p>
            <p>①生动性、娱乐性的学习模式</p>
            <p>
              ②对有用的内容和有实际应用的学习内容感兴趣 倾向性顺序： ①思维 ②感觉
              ③直觉 ④情感
            </p>
            <p>（3）问题解决模式：</p>
            <p>①喜欢依据具体事实以自身具有的内部逻辑构建问题 和解决问题</p>
            <p>②为获得理想结果，需要考虑其它可能性和对人们的影响</p>
            <p>工作环境倾向性：</p>
            <p>①喜欢与行为定向、关注即时情境的人共事</p>
            <p>②计划定向和任务定向的环境</p>
            <p>③重视理性分析的环境</p>
            <p>④奖励对问题做出快速反应的环境</p>
            <p>⑤允许间接经验的环境</p>
            <p>⑥提供合适的工作自由度的环境</p>
            <p>⑦培养独立性和自主性的环境</p>
            <p>潜在的缺点：</p>
            <p>①只关注对自身重要的事而对其它事漠不关心</p>
            <p>②在先前的努力获得成果前，缺少坚持性</p>
            <p>③努力不足，过度注重有利性而走捷径</p>
            <p>④犹豫不决，欠缺兴趣、活力、坚持性</p>
            <p>发展建议：</p>
            <p>①需要增强开放性，关心他人，与他人共享信息</p>
            <p>②需发展坚持性，改变沟通模式</p>
            <p>③加强计划性，付出更多努力获取想要的成功</p>
            <p>④需发展设置和保持目标的方法</p>
            <p>
              适合领域：技术领域 证券、金融业 贸易、商业领域
              户外、运动、艺术等领域
            </p>
            <p>
              适合职业：机械、电气、电子工程师、各类技术专家和技师、计算机硬件、系统集成专业人员等；证券分析师、金融、财务顾问、经济学研究者等；贸易商、商品经销商、产品代理商（有形产品为主）等；警察、侦探、体育工作者、赛车手、飞行员、雕塑家、手工制作、画家等。
            </p>
          </div>
          <div v-else-if="mbti_type == 'ISFP'">
            <p>1.羞怯的、安宁和善地、敏感的、亲切的、且行事谦虚。</p>
            <p>2.喜于避开争论，不对他人强加已见或价值观。</p>
            <p>3.无意于领导却常是忠诚的追随者。</p>
            <p>
              4.办事不急躁，安于现状无意于以过度的急切或努力破坏现况，且非成果导向。
            </p>
            <p>5.喜欢有自有的空间及照自订的时程办事。</p>
            <p>（1）领导模式：</p>
            <p>①不喜欢担任领导，喜欢在团队中担任协调者的角色</p>
            <p>②用自己个人的忠诚激发他人工作的积极性</p>
            <p>③常常更多地采用表扬和支持的方式，较少批评他人</p>
            <p>④随环境所需而做调适</p>
            <p>⑤通过了解他人良好的意图温和地说服他人</p>
            <p>（2）学习模式：</p>
            <p>①安静地学习直接经验</p>
            <p>
              ②学习实际的、能帮助他人的内容 倾向性顺序： ①情感 ②感觉 ③直觉 ④思维
            </p>
            <p>（3）问题解决模式：</p>
            <p>①喜欢从实用的角度考虑对自己和他人真正重要的事物</p>
            <p>
              ②为获得理想结果，需考虑其他人际关系和其它可能性，
              然后更客观地决定事情
            </p>
            <p>工作环境倾向性：</p>
            <p>①喜欢与合作的、安静地享受工作愉悦感的人共事</p>
            <p>②允许有个人空间的工作环境</p>
            <p>③与致力于和谐相处的人共事</p>
            <p>④能提供灵活性和安全感的环境</p>
            <p>⑤有艺术感染力的环境</p>
            <p>⑥讲究礼貌的同事</p>
            <p>⑦追求实际效果的环境</p>
            <p>潜在的缺点：</p>
            <p>①可能太信任他人，不愿持怀疑态度</p>
            <p>②为避免冲突而不批评他人</p>
            <p>③只关注眼前的损失</p>
            <p>④过度自我批评，容易受伤害</p>
            <p>发展建议：</p>
            <p>①需发展以怀疑的态度分析他人提供的信息</p>
            <p>②需学会给他人负面反馈，处理好冲突</p>
            <p>③需发展更广阔、更朝向未来定向的观念</p>
            <p>④需对他人更果断，对自己有更多赞赏</p>
            <p>适合领域：手工艺、艺术领域 医护领域 商业、服务业领域</p>
            <p>
              适合职业：时装、首饰设计师、装潢、园艺设计师、陶器、乐器、卡通、漫画制作者、素描画家、舞蹈演员、画家等；出诊医生、出诊护士、理疗师、牙科医生、个人健康和运动教练等；餐饮业、娱乐业业主、旅行社销售人员、体育用品、个人理疗用品销售员等。
            </p>
          </div>
          <div v-else-if="mbti_type == 'INFP'">
            <p>1安静观察者，具理想性与对其价值观及重要之人具忠诚心。</p>
            <p>2.希外在生活形态与内在价值观相吻合。</p>
            <p>3.具好奇心且很快能看出机会所在。常担负开发创意的触媒者。</p>
            <p>4.除非价值观受侵犯，行事会具弹性、适应力高且承受力强。</p>
            <p>5.具想了解及发展他人潜能的企图。想作太多且作事全神贯注。</p>
            <p>6.对所处境遇及拥有不太在意。</p>
            <p>7.具适应力、有弹性除非价值观受到威胁。</p>
            <p>（1）领导模式：</p>
            <p>①采用便利的领导方式</p>
            <p>②倾向发挥独特的领导能力而不是担当传统性的领导角色</p>
            <p>③独立工作</p>
            <p>④更可能表扬而不是批评他人</p>
            <p>⑤鼓励员工以行动实现理想</p>
            <p>（2）学习模式：</p>
            <p>①安静地专注于自己的兴趣，展示出丰富的想象力</p>
            <p>
              ②灵活，专注自己和他人的发展 倾向性顺序： ①情感 ②直觉 ③感觉 ④思考
            </p>
            <p>（3）解决问题模式 ：</p>
            <p>①思考真正对他人和自己重要的问题，找出具有创造性的可能性</p>
            <p>②为获得最佳结果注意搜集事实资料客观地做出决策</p>
            <p>工作环境倾向性：</p>
            <p>①喜欢处在有重要价值观、令人愉悦、效忠组织的人们中</p>
            <p>②处在合作、轻松氛围的环境里</p>
            <p>③允许个体性，也提供共同参与的环境</p>
            <p>④环境中提供灵活、有弹性的工作程序，最少的常规</p>
            <p>⑤非官僚主义的环境</p>
            <p>⑥安静的环境</p>
            <p>⑦有时间和空间进行思考的工作环境</p>
            <p>潜在的缺点：</p>
            <p>①因完美倾向而延误完成任务</p>
            <p>②一次行为想令太多人满意</p>
            <p>③没有调整理想适合客观现实</p>
            <p>④思考多于行动</p>
            <p>发展建议：</p>
            <p>①需要学会怎样工作而不是只注意寻求理想的反应</p>
            <p>②需要发展更坚强的意志，并愿意说“不”</p>
            <p>③需要用自己的准则分清事实和逻辑</p>
            <p>④需要建立和执行行动计划</p>
            <p>适合领域：创作性、艺术类 教育、研究、咨询类</p>
            <p>
              适合职业：各类艺术家、插图画家、诗人、小说家、建筑师、设计师、文学编辑、艺术指导、记者等；大学老师（人文类）、心理学工作者、心理辅导和咨询人员、社科类研究人员、社会工作者、教育顾问、图书管理者、翻译家等。
            </p>
          </div>
          <div v-else-if="mbti_type == 'INTP'">
            <p>1.安静、自持、弹性及具适应力。</p>
            <p>2.特别喜爱追求理论与科学事理。</p>
            <p>3.习于以逻辑及分析来解决问题—问题解决者。</p>
            <p>4.最有兴趣于创意事务及特定工作，对聚会与闲聊无大兴趣。</p>
            <p>5.追求可发挥个人强烈兴趣的生涯。</p>
            <p>6.追求发展对有兴趣事务之逻辑解释。</p>
            <p>（1）领导模式:</p>
            <p>①在概念上分析问题和目标</p>
            <p>②提供逻辑思维模式</p>
            <p>③追求自主性的同时，也关注他人独立的领导模式</p>
            <p>④依据他人的专业知识而非职位与其交往</p>
            <p>⑤追求与他人智慧上的交流而非情感交流</p>
            <p>（2）学习模式:</p>
            <p>
              ①个体化学习方式，不设置开始与结束，只根据自己感兴趣的深度进行学习
            </p>
            <p>
              ②广泛的、概念性的、能挑战智慧的学习方式 倾向性顺序:①思维 ②直觉
              ③感觉 ④情感
            </p>
            <p>（3）解决问题模式:</p>
            <p>
              ①在寻求各种可能的选择时，喜欢以自身内部的逻辑建构问题和解决问题
            </p>
            <p>②为获取最佳结果，需同时关注现实状况和他人需求</p>
            <p>工作环境倾向性:</p>
            <p>①喜欢与独立思考者、关注解决复杂问题的人共事</p>
            <p>②环境中允许个体有充足的时间和空间进行思考</p>
            <p>③能培养思维独立性和创造性的环境</p>
            <p>④能提供灵活的政策和程序的环境</p>
            <p>⑤安静、尽可能少地开会的环境</p>
            <p>⑥非结构化和非官僚作风的环境</p>
            <p>⑦奖励自我决定的环境</p>
            <p>潜在的缺点:</p>
            <p>①过于抽象，因而坚持下去不太符合现实需要</p>
            <p>②过于理性化，解释起来太理论化</p>
            <p>③过多注意团队中一些小的不一致的地方</p>
            <p>④可能以批评式分析方式待人，行动不考虑个体感受</p>
            <p>发展建议:</p>
            <p>①需要关注现实中的细节，确立完成任务的具体步骤</p>
            <p>②需要简单地陈述事实</p>
            <p>③为获得他人的合作，需要放弃细小的问题</p>
            <p>④需要更好地认识他人，更多地表达对他人的赞赏</p>
            <p>
              适合领域：计算机技术 理论研究、学术领域 专业领域 创造性领域
            </p>
            <p>
              适合职业：软件设计员、系统分析师、计算机程序员、数据库管理、故障排除专家等
              大学教授、科研机构研究人员、数学家、物理学家、经济学家、考古学家、历史学家等
              证券分析师、金融投资顾问、律师、法律顾问、财务专家、侦探等；各类发明家、作家、设计师、音乐家、艺术家、艺术鉴赏家等。
            </p>
          </div>
          <div v-else-if="mbti_type == 'ESTP'">
            <p>1.擅长现场实时解决问题—解决问题者。</p>
            <p>2.喜欢办事并乐于其中及过程。</p>
            <p>3.倾向于喜好技术事务及运动，交结同好友人。</p>
            <p>4.具适应性、容忍度、务实性；投注心力于会很快具成效工作。</p>
            <p>5.不喜欢冗长概念的解释及理论。</p>
            <p>6.最专精于可操作、处理、分解或组合的真实事务。</p>
            <p>（1）领导模式：</p>
            <p>①对危机中的管理有充分准备</p>
            <p>②说服他人接受自己的观点</p>
            <p>③直率、自信的领导方式</p>
            <p>④按最有利的路径进行组织工作</p>
            <p>⑤重视行动和即时结果</p>
            <p>（2）学习模式：</p>
            <p>①主动型、间接经验型、尝试错误型的学习方式</p>
            <p>
              ②实际型，注意力集中在即刻能应用的学习内容 倾向性顺序： ①感觉 ②思维
              ③情感 ④直觉
            </p>
            <p>（3）问题解决模式：</p>
            <p>①喜欢现实、具体地评估环境，然后用逻辑分析以后采 取的步骤</p>
            <p>
              ②为获得理想结果，会考虑对人们的影响，寻找其它可选择的可能性
            </p>
            <p>工作环境倾向性：</p>
            <p>①喜欢与活泼、结果定向型、重视直接经验的人共事</p>
            <p>②有规则，但承认差异性的环境</p>
            <p>③环境中允许有开玩笑的时间</p>
            <p>④能提供工作灵活性的环境</p>
            <p>⑤技术型定向的环境，有最新的设备</p>
            <p>⑥身体感到舒适的环境</p>
            <p>⑦对即刻的需求能做出反应的环境</p>
            <p>潜在的缺点：</p>
            <p>①当快速行为时，显得苛求、强硬、感觉迟钝</p>
            <p>②过分集中于即时行为，而失去行为的更广阔、深远的意义</p>
            <p>③转移到下一个待解决问题而不能坚持解决目前的问题</p>
            <p>④会被工作以外的活动吸引，如体育运动和其它娱乐活动。</p>
            <p>发展建议：</p>
            <p>①需抑制自己的任务型定向，分析他人的情绪感受</p>
            <p>②需在快速决定之前，事先计划，考虑更多的因素</p>
            <p>③需完成眼前的任务</p>
            <p>④需以适当的观点看待工作和娱乐</p>
            <p>
              适合领域：贸易、商业、某些特殊领域 服务业 金融证券业
              娱乐、体育、艺术领域
            </p>
            <p>
              适合职业：各类贸易商、批发商、中间商、零售商、房地产经纪人、保险经济人、汽车销售人员、私家侦探、警察等；
              餐饮、娱乐及其他各类服务业的业主、主管、特许经营者、自由职业者等。
              股票经纪人、证券分析师、理财顾问、个人投资者等；娱乐节目主持人、体育节目评论、脱口秀、音乐、舞蹈表演者、健身教练、体育工作者等。
            </p>
          </div>
          <div v-else-if="mbti_type == 'ESFP'">
            <p>1.外向、和善、接受性、乐于分享喜乐予他人。</p>
            <p>2.喜欢与他人一起行动且促成事件发生，在学习时亦然。</p>
            <p>3.知晓事件未来的发展并会热列参与。</p>
            <p>
              5.最擅长于人际相处能力及具备完备常识，很有弹性能立即　适应他人与环境。
            </p>
            <p>6.对生命、人、物质享受的热爱者。</p>
            <p>（1）领导模式：</p>
            <p>①促进善意和合作的领导方式</p>
            <p>②喜欢从开头管理某个工程</p>
            <p>③消除紧张气氛，把人们带入轻松的情境里</p>
            <p>④关注解决即时出现的问题</p>
            <p>⑤促进人际间有效的交流</p>
            <p>（2）学习模式：</p>
            <p>①利用充裕的时间通过讨论获取新知识</p>
            <p>
              ②学习事实性的知识，搞清楚这些知识如何发挥作用的 倾向性顺序： ①感觉
              ②情感 ③思维 ④直觉
            </p>
            <p>（3）解决问题模式：</p>
            <p>①喜欢对情境进行现实和具体的评估，尤其是对于人更是如此</p>
            <p>②为获得最佳结果，需增强客观性，从长远的眼光看待</p>
            <p>不同事物</p>
            <p>工作环境倾向性：</p>
            <p>①喜欢与有活力的、轻松愉快、关注现实的人共事</p>
            <p>②活跃、行为定向的工作环境</p>
            <p>③培养快节奏做事的环境</p>
            <p>④有适应性强、喜爱自由的人的工作环境</p>
            <p>⑤强调和谐、友好、赞赏别人的环境</p>
            <p>⑥乐观的、注重交往的工作环境</p>
            <p>⑦有吸引力、丰富多彩的环境</p>
            <p>潜在的缺陷：</p>
            <p>①为保持和谐，过度强调主观性论据</p>
            <p>②行动前不太考虑眼前的事实</p>
            <p>③可能花太多的时间在社会关系上而忽视任务本身</p>
            <p>④常常有始无终</p>
            <p>发展建议：</p>
            <p>①为减少非个体性冲突，做决策时需理智分析决策的意义</p>
            <p>②进行管理工作前应事先制定计划</p>
            <p>③需平衡花费在任务和社会性交往上的时间</p>
            <p>④需致力于完成计划，对时间进行管理</p>
            <p>
              适合领域：消费类商业、服务业领域
              广告业、娱乐业领域、旅游业、社区服务等其他领域
            </p>
            <p>
              适合职业：精品店、商场销售人员、娱乐、餐饮业客户经理、房地产销售人员、汽车销售人员、市场营销人员（消费类产品）等；广告企业中的设计师、创意人员、客户经理、时装设计和表演人员、摄影师、节目主持人、脱口秀演员等；旅游企业中的销售、服务人员、导游、社区工作人员、自愿工作者、公共关系专家、健身和运动教练、医护人员等。
            </p>
          </div>
          <div v-else-if="mbti_type == 'ENFP'">
            <p>
              1.充满热忱、活力充沛、聪明的、富想象力的，视生命充满机会但期能得自他人肯定与支持。
            </p>
            <p>2.几乎能达成所有有兴趣的事。</p>
            <p>3.对难题很快就有对策并能对有困难的人施予援手。</p>
            <p>4.依赖能改善的能力而无须预作规划准备。</p>
            <p>5.为达目的常能找出强制自己为之的理由。</p>
            <p>6.即兴执行者。</p>
            <p>（1）领导模式：</p>
            <p>①富有活力、热情的领导方式</p>
            <p>②喜欢进行首创性管理</p>
            <p>③经常是重要事件的发言人</p>
            <p>④工作中提倡和支持人们的自主性</p>
            <p>⑤关注如何激励他人，如何鼓励他人付诸于行动</p>
            <p>（2）学习模式：</p>
            <p>①积极主动型、经验型、想象型</p>
            <p>
              ②对学习内容感兴趣，不管它们是否有实际应用性 倾向性顺序：①直觉
              ②情感 ③思维 ④感觉
            </p>
            <p>（3）解决问题模式：</p>
            <p>
              ①喜欢根据自己的价值观和准则探索创造性发展的各种可能性和前景
            </p>
            <p>
              ②为获得最佳结果，冷静理智分析，考虑相关的事实资料和各种细节
            </p>
            <p>工作环境倾向性：</p>
            <p>①喜欢与想象力丰富、致力于人们未来发展的人共事</p>
            <p>②允许表现交际能力和智力才能的环境</p>
            <p>③爱好参与的氛围，与不同的人分享不同的观点</p>
            <p>④提供变化和具有挑战性的环境</p>
            <p>⑤鼓励提出观点和想法的环境</p>
            <p>⑥有弹性、自由度大、少限制的环境</p>
            <p>⑦气氛愉悦和随意的环境</p>
            <p>潜在的缺点：</p>
            <p>①在没完成已经提出的计划之前又转移到新的想法和计划上</p>
            <p>②忽视相关的细节和事实资料</p>
            <p>③过分扩展，尝试做的事情太多</p>
            <p>④因寻求可能的最佳结果而拖延工作</p>
            <p>发展建议：</p>
            <p>①需要根据重要性事先做好安排，先做最重要的，坚持到底。</p>
            <p>②需要关注重要的细节</p>
            <p>③需要学会筛选任务，不要试图去做所有具有吸引力的任务。</p>
            <p>④为达成目标，需使用制定计划和进行时间管理的技巧</p>
            <p>适合领域：</p>
            <p>
              广告创意、广告撰稿人，市场营销和宣传策划、市场调研人员、艺术指导、公关专家、公司对外发言人等.
            </p>
            <p>适合职业：</p>
            <p>
              儿童教育老师、大学老师（人文类）、心理学工作者、心理辅导和咨询人员、职业规划顾问、社会工作者、人力资源专家、培训师、演讲家等
              记者（访谈类）、节目策划和主持人、专栏作家、剧作家、艺术指导、设计师、卡通制作者、电影、电视制片人等。
            </p>
          </div>
          <div v-else-if="mbti_type == 'ENTP'">
            <p>1.反应快、聪明、长于多样事务。</p>
            <p>2.具激励伙伴、敏捷及直言讳专长。</p>
            <p>3.会为了有趣对问题的两面加予争辩。</p>
            <p>
              4.对解决新及挑战性的问题富有策略，但会轻忽或厌烦经常的任务与细节。
            </p>
            <p>5.兴趣多元，易倾向于转移至新生的兴趣。</p>
            <p>6.对所想要的会有技巧地找出逻辑的理由。</p>
            <p>7.长于看清础他人，有智能去解决新或有挑战的问题</p>
            <p>（1）领导模式:</p>
            <p>①制定理论体系满足组织的需要</p>
            <p>②鼓励他人的独立性</p>
            <p>③运用逻辑分析寻找变革的模式</p>
            <p>④对于自己想做的事使用强制性的理由</p>
            <p>⑤在人与组织之间扮演促进者的角色</p>
            <p>（2）学习模式:</p>
            <p>①主动、概念型的学习，喜欢由专家传授知识</p>
            <p>
              ②接受挑战型，定向于未来的发展蓝图 倾向性顺序： ①直觉 ②思维 ③情感
              ④感觉
            </p>
            <p>（3）解决问题模式:</p>
            <p>
              ①喜欢探索未来的前景和发展模式，理智地分析每一个正向和反向的结果
            </p>
            <p>②为获得最理想结果，关注人们的需要和相关的事实和细节</p>
            <p>
              工作环境倾向性:①喜欢与独立的、按理论模型解决复杂问题的人共事
              ②提供灵活性和挑战性的工作环境
            </p>
            <p>③变革型和非官僚作风的工作环境</p>
            <p>④拥有具有竞争力的个体的环境</p>
            <p>⑤奖励挑战风险行为的工作环境</p>
            <p>⑥鼓励行为自主性和自由性的环境</p>
            <p>⑦关注未来发展远景的工作环境</p>
            <p>潜在的缺点:</p>
            <p>
              ①过多依赖模型而忘记现实状况 ②因竞争心而不会赞赏他人的付出
              ③因过分扩展自己而筋疲力尽 ④可能抵制正规的程序和准则
            </p>
            <p>发展建议:</p>
            <p>①需要注意各个方面的因素和基本的事实</p>
            <p>②需要承认他人贡献的有效性</p>
            <p>③需要设立现实性的开始与结束的期限，知道何时该结束</p>
            <p>④需要学会怎样在组织里如何工作</p>
            <p>
              适合领域：投资顾问、项目策划、投资银行、自我创业
              市场营销、创造性领域 公共关系 政治
            </p>
            <p>
              适合职业：投资顾问（房地产、金融、贸易、商业等）、各类项目的策划人和发起者、投资银行家、风险投资人、企业业主（新兴产业）等；市场营销人员、各类产品销售经理、广告创意、艺术总监、访谈类节目主持人、制片人等；公共关系专家、公司对外发言人、社团负责人、政治家等。
            </p>
          </div>
          <div v-else-if="mbti_type == 'ESTJ'">
            <p>1.务实、真实、事实倾向，具企业或技术天份。</p>
            <p>2.不喜欢抽象理论；最喜欢学习可立即运用事理。</p>
            <p>3.喜好组织与管理活动且专注以最有效率方式行事以达致成效。</p>
            <p>4.具决断力、关注细节且很快作出决策—优秀行政者。</p>
            <p>5.会忽略他人感受。</p>
            <p>6.喜作领导者或企业主管。</p>
            <p>（1）领导模式：</p>
            <p>①直接领导，快速管理</p>
            <p>②运用过去经验解决问题</p>
            <p>③直接、明确地识别问题的核心</p>
            <p>④决策和执行决策非常迅速</p>
            <p>⑤传统型领导，尊重组织内部的等级和组织获得的成就</p>
            <p>（2）学习模式：</p>
            <p>①积极主动型，学习间接经验，采用结构化的学习方式</p>
            <p>
              ②实际型，关注他们能运用的学习内容 向性顺序： ①思维 ②感觉 ③直觉
              ④情感
            </p>
            <p>（3）问题解决模式：</p>
            <p>①喜欢根据相关的事实和细节进行逻辑分析，从而控 制情境</p>
            <p>②为达到理想结果，会考虑更广阔的前景以及对人们和自己的影响</p>
            <p>工作环境倾向性：</p>
            <p>①喜欢与努力工作、有坚定决心把工作做好的人共事</p>
            <p>②任务型定向的环境</p>
            <p>③有组织和组织结构的环境</p>
            <p>④有团队计划的环境</p>
            <p>⑤提供稳定性和预测性的环境</p>
            <p>⑥致力于绩效和生产性的环境</p>
            <p>⑦奖励完成目标的环境</p>
            <p>潜在的缺点：</p>
            <p>①决策太迅速，也给他人施以同样的压力</p>
            <p>②不能察觉变革的需要，因为相信一切都在正常运作</p>
            <p>③在完成任务过程中，忽视人际间的小细节</p>
            <p>④长期忽视自己的感受和准则，可能会被自己的情感击跨</p>
            <p>发展建议：</p>
            <p>①决策之前需考虑各种因素，包括人的因素</p>
            <p>②需要促使自己看到他人要求变革而获得的利益</p>
            <p>③需做特别的努力学会赞赏别人</p>
            <p>④需从工作中抽点时间考虑和识别自己的情感和价值观</p>
            <p>适合领域：无明显领域特征</p>
            <p>
              适合职业：大、中型外资企业员工、业务经理、中层经理（多分布在财务、营运、物流采购、销售管理、项目管理、工厂管理、人事行政部门）、职业经理人、各类中小型企业主管和业主。
            </p>
          </div>
          <div v-else-if="mbti_type == 'ESFJ'">
            <p>
              1.诚挚、爱说话、合作性高、受欢迎、光明正大
              的—天生的合作者及活跃的组织成员。
            </p>
            <p>2.重和谐且长于创造和谐。</p>
            <p>3.常作对他人有益事务。</p>
            <p>4.给予鼓励及称许会有更佳工作成效。</p>
            <p>5.最有兴趣于会直接及有形影响人们生活的事务。</p>
            <p>6.喜欢与他人共事去精确且准时地完成工作。</p>
            <p>（1）领导模式：</p>
            <p>①关心他人的领导方式</p>
            <p>②以良好的人际关系赢得合作</p>
            <p>③让人们活跃起来</p>
            <p>④承担繁重的工作，坚持到底</p>
            <p>⑤发扬组织的传统精神</p>
            <p>（2）学习模式：</p>
            <p>①系统性、参与性、个体性的学习方式，用较多时间讨论新知识</p>
            <p>
              ②学习早已有实践应用的应用性材料 倾向性顺序： ①情感 ②感觉 ③直觉
              ④思维
            </p>
            <p>（3）解决问题模式：</p>
            <p>
              ①喜欢考虑准则以及对人们的影响，也关注相关的事实和有用的细节
            </p>
            <p>
              ②为获取理想结果，需识别其它人际关系，然后理智、 冷静地分析
            </p>
            <p>工作环境倾向性：</p>
            <p>①喜欢与诚恳、具有合作性、乐于帮助他人的人共事</p>
            <p>②目标定向型的环境，提供有益、合适的工作程序</p>
            <p>③奖励组织行为和个体工作绩效的环境</p>
            <p>④鼓励人际间的友谊的工作环境</p>
            <p>⑤赞赏他人和开朗的工作氛围</p>
            <p>⑥培养人际间关心和敏感察觉他人需要的工作环境</p>
            <p>⑦关注事实和价值观的环境</p>
            <p>潜在的缺点：</p>
            <p>①避免和回避冲突</p>
            <p>②因致力于令他人满意而忽略自己</p>
            <p>③提供自己认为是对组织和对他人最好的建议</p>
            <p>④不经常有时间客观地反思过去、展想未来</p>
            <p>发展建议：</p>
            <p>①需学会注意差异性和处理冲突</p>
            <p>②需学会分离出自己的需要</p>
            <p>③需学会更客观地听取真正需要什么</p>
            <p>④做决策时，需考虑决策的理性、全局性的意义</p>
            <p>适合领域：无明显领域特征</p>
            <p>
              适合职业：办公室行政或管理人员、秘书、总经理助理、项目经理、客户服务部人员、采购和物流管理人员等；内科医生及其它各类医生、牙科医生、护士、健康护理指导师、饮食学、营养学专家、小学教师（班主任）、学校管理者等；银行、酒店、大型企业客户服务代表、客户经理、公共关系部主任、商场经理、餐饮业业主和管理人员等。
            </p>
          </div>
          <div v-else-if="mbti_type == 'ENFJ'">
            <p>1.热忱、易感应及负责任的--具能鼓励他人的领导风格。</p>
            <p>2.对别人所想或希求会表达真正关切且切实用心去处理。</p>
            <p>3.能怡然且技巧性地带领团体讨论或演示文稿提案。</p>
            <p>4.爱交际、受欢迎及富同情心。</p>
            <p>5.对称许及批评很在意。</p>
            <p>6.喜欢带引别人且能使别人或团体发挥潜能。</p>
            <p>（1）领导模式：</p>
            <p>①富于热情和赞扬他人的领导方式</p>
            <p>②以参与的态度管理员工和工作</p>
            <p>③满足员工的需要，努力使每个员工满意</p>
            <p>④促使组织的行为与组织的价值观一致</p>
            <p>⑤鼓励施行给人们带来利益的变革</p>
            <p>（2）学习模式：</p>
            <p>①在相互交流和合作中学习重要的内容</p>
            <p>
              ②有良好结构的学习材料，在学习中能获得更多鼓励 倾向性顺序：①情感
              ②直觉 ③感觉 ④思维
            </p>
            <p>（3）解决问题模式：</p>
            <p>①先判断发展计划是否考虑能取得的绩效和对人们的影响</p>
            <p>②为获得最佳结果注意更多事实资料，然后进行理智、冷静地分析</p>
            <p>喜欢的工作环境：</p>
            <p>①喜欢与那些关注变革并通过变革改变人们的人共事</p>
            <p>②喜欢人际定向型和社会型的环境</p>
            <p>③鼓励支持和称赞他人的环境</p>
            <p>④富有同情精神和和睦气氛的环境</p>
            <p>⑤鼓励自我表现的环境</p>
            <p>⑥稳定而注重果断性的环境</p>
            <p>⑦注重反馈和秩序的环境</p>
            <p>潜在的缺点：</p>
            <p>①可能会理想化他人，因而遭受他人表面忠诚的蒙蔽</p>
            <p>②可能回避有冲突的问题</p>
            <p>③因重视人际关系而忽视任务</p>
            <p>④过度自我批评</p>
            <p>发展建议：</p>
            <p>①需要认识人们的局限性，捍卫真正的忠诚</p>
            <p>②需要学会建设性地处理冲突</p>
            <p>③需要学会同时关注任务中的细节问题和完成任务的人</p>
            <p>④需要认真听取客观的评价，少一些自我批评</p>
            <p>适合领域：培训、咨询、教育 新闻传播、公共关系、文化艺术</p>
            <p>
              适合职业：人力资源培训主任、销售、沟通、团队培训员、职业指导顾问、心理咨询工作者、大学教师（人文学科类）、教育学、心理学研究人员等
              记者、撰稿人、节目主持人（新闻、采访类）、公共关系专家、社会活动家、文艺工作者、平面设计师、画家、音乐家等。
            </p>
          </div>
          <div v-else-if="mbti_type == 'ENTJ'">
            <p>1.坦诚、具决策力的活动领导者。</p>
            <p>2.长于发展与实施广泛的系统以解决组织的问题。</p>
            <p>3.专精于具内涵与智能的谈话如对公众演讲。</p>
            <p>4.乐于经常吸收新知且能广开信息管道。</p>
            <p>5.易生过度自信，会强于表达自已创见。</p>
            <p>6.喜于长程策划及目标设定</p>
            <p>（1）领导模式:</p>
            <p>①富于活力的行为定向型领导模式</p>
            <p>②为组织制定长远规划</p>
            <p>③必要时，采用直接的、强硬的管理方式</p>
            <p>④喜欢复杂问题并足智多谋地解决这些问题</p>
            <p>⑤尽可能多地参与组织管理</p>
            <p>（2）学习模式:</p>
            <p>
              ①喜欢由专家传授知识 ②对挑战和问题抱开放态度 倾向性顺序: ①思维
              ②直觉 ③感觉 ④情感
            </p>
            <p>（3）解决问题模式:</p>
            <p>①根据内在的理解进行逻辑分析从而控制局面</p>
            <p>
              ②为获得理想结果，对事实资料进行现实性决策，同时考虑决策对人们和自己的
              影响
            </p>
            <p>工作环境倾向性:</p>
            <p>
              ①喜欢与结果定向型、独立的、有能力的、注重解决复杂问题的人共事
            </p>
            <p>②目标型定向的工作环境</p>
            <p>③有效率的组织系统和员工</p>
            <p>④工作环境中即刻奖励做出挑战努力的员工</p>
            <p>⑤奖励果断的人的环境</p>
            <p>⑥环境中有意志坚定的人</p>
            <p>⑦组织关注主要的组织计划</p>
            <p>潜在的缺点:</p>
            <p>①关注任务而忽视人们的需要和对组织的贡献</p>
            <p>②忽略现实的考虑和对现实局限性的认识</p>
            <p>③决策太迅速，表现得缺乏耐心，盛气凌人</p>
            <p>④忽视和抑制自己和他人的情感</p>
            <p>发展建议:</p>
            <p>①需要考虑人的因素，赞赏他人对组织的贡献</p>
            <p>②行动前先检查现实的、人力的、环境的资源是否可获得</p>
            <p>③决策前花些时间考虑和反思各个方面的因素</p>
            <p>④需要学会鉴别和重视自己和他人的情感</p>
            <p>
              适合领域： 工商业、政界 金融和投资领域 管理咨询、培训 专业性领域
            </p>
            <p>
              适合职业：
              各类企业的高级主管、总经理、企业主、社会团体负责人、政治家等
              投资银行家、风险投资家、股票经纪人、公司财务经理、财务顾问、经济学家
              企业管理顾问、企业战略顾问、项目顾问、专项培训师等；律师、法官、知识产权专家、大学教师、科技专家等。
            </p>
          </div>
        </div>
      </div>
      <p class="page-footer no-indent">
        本产品由灯塔教育专利智能专业匹配算法驱动（专利号：201810642977.1）
      </p>
    </div>
    <div class="print-page">
      <h1>相关知识</h1>
      <p>
        人的性格倾向，就象分别使用自己的两只手写字一样，都可以写出来，但惯用的那只写出的会比另一只更好。MBTI是根据荣格的心理类型理论著成，目前已经成为权威的性格测试，Psytopic特别制作了这一在线测试，希望能帮助一些朋友。欢迎人力资源部的朋友，在招聘和任职中试用本测试；同时也可作为个人职业生涯规划分析的参考工具。
      </p>
      <p>
        MBTI（Myers-Briggs Type
        Indicator），一种迫选型、自我报告式的性格评估测试，用以衡量和描述人们在获取信息、作出决策、对待生活等方面的心理活动规律和性格类型。
      </p>
      <p>
        MBTI人格理论的基础是著名心理学家卡尔·荣格先生关于心理类型的划分，后由美国的心理学家Katharine
        Cook Briggs 与Isabel Briggs Myers研究并加以发展。
      </p>
      <p>
        Katherine Cook Briggs (1875-1968) 和她的心理学家女儿Isabel Briggs
        Myers根据瑞士著名的心理分析学家Carl G. Jung
        (荣格)的心理类型理论和她们对于人类性格差异的长期观察和研究而著成。经过了长达50多年的研究和发展，MBTI已经成为了当今全球最为著名和权威的性格测试。
      </p>
      <p>
        这种理论可以帮助解释为什么不同的人对不同的事物感兴趣、善长不同的工作、并且有时不能互相理解。这个工具已经在世界上运用了将近30年的时间，夫妻利用它增进融洽、老师学生利用它提高学习、授课效率，青年人利用它选择职业，组织利用它改善人际关系、团队沟通、组织建设、组织诊断等多个方面。在世界五百强中，有80%的企业有MBTI的应用经验。
      </p>
      <p>
        MBTI主要应用于职业发展、职业咨询、团队建议、婚姻教育等方面，是目前国际上应用较广的人才甄别工具。
      </p>
      <p>
        人的性格倾向，就象分别使用自己的两只手写字一样，都可以写出来，但惯用的那只写出的会比另一只更好。每个人都会沿着自己所属的类型发展出个人行为、技巧和态度，而每一种也都存在着自己的潜能和潜在的盲点。本次测试主要探讨各种性格类型与相关职业的匹配程度。
      </p>
      <div class="button-wrapper">
        <a
          class="lj-btn lj-btn-primary"
          v-if="!questionaireDone"
          @click="jumpPage('HomeMajorStdMatchPage')"
          style="margin-right: 1rem;"
          >继续测评</a
        ><a
          class="lj-btn lj-btn-secondary"
          @click="jumpPage('reportCenter')"
          style="margin-right: 1rem;"
          >返回测评导航</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import './../../../../Public/utils/chartjs/Chart'
import './../../../../Public/utils/highcharts/highcharts'
import { queryMBIT, queryMajorDone } from './../../../../api/major'

export default {
  data: function () {
    return {
      mbti_type: 'ENTJ',
      mbti_e: 80,
      mbti_i: 20,
      mbti_s: 30,
      mbti_n: 70,
      mbti_t: 10,
      mbti_f: 90,
      mbti_j: 20,
      mbti_p: 80,
      mbti_tz: 50,
      mbti_aq: 50,

      questionaireDone: true
    }
  },
  computed: {
    isMobile: function () {
      return navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    },
    chartOptions: function () {
      return {
        chart: { type: 'bar' },
        title: { text: 'MBTI测评结果' },
        xAxis: [
          { categories: ['TZ', 'J', 'T', 'S', 'E'], reversed: !1 },
          {
            opposite: !0,
            reversed: !1,
            categories: ['AQ', 'P', 'F', 'N', 'I'],
            linkedTo: 0
          }
        ],
        yAxis: {
          title: { text: '比例' },
          labels: {
            formatter: function () {
              return 100 - Math.abs(this.value) + '%'
            }
          },
          min: -100,
          max: 0
        },
        plotOptions: { series: { stacking: 'normal' } },
        tooltip: {
          formatter: function () {
            return (
              '<b>类型 ' +
              this.point.category +
              '</b><br/>比例 ' +
              Math.abs(this.point.y) +
              '%'
            )
          }
        },
        series: [
          {
            name: 'ESTJ',
            data: [
              -this.mbti_tz,
              -this.mbti_j,
              -this.mbti_t,
              -this.mbti_s,
              -this.mbti_e
            ],
            color: '#f17c67'
          },
          {
            name: 'INFP',
            data: [
              -this.mbti_aq,
              -this.mbti_p,
              -this.mbti_f,
              -this.mbti_n,
              -this.mbti_i
            ],
            color: '#2EA9DF'
          }
        ]
      }
    }
  },
  methods: {
    print: function () {
      this.$router.push({
        path: 'HomeMajorStdPrint'
      })
    },
    getMBTIType () {
      this.mbti_type = ''
      this.mbti_type += this.mbti_e > this.mbti_i ? 'E' : 'I'
      this.mbti_type += this.mbti_s > this.mbti_n ? 'S' : 'N'
      this.mbti_type += this.mbti_t > this.mbti_f ? 'T' : 'F'
      this.mbti_type += this.mbti_j > this.mbti_p ? 'J' : 'P'
    },

    jumpPage: function (dest) {
      dest === '/'
        ? this.$router.replace({ path: dest })
        : this.$router.push({ path: dest })
    }
  },
  mounted: function () {
    if (this.isMobile === null) {
      this.$router.push({
        path: 'HomeMajorStdResultPage2'
      })
      return
    }
    let that = this
    queryMBIT({}).then(res => {
      if (res.rescode === 200) {
        console.log(res)
        that.getMBTIType()
        that.mbti_e = res.data.mbti_e
        that.mbti_i = res.data.mbti_i
        that.mbti_s = res.data.mbti_s
        that.mbti_n = res.data.mbti_n
        that.mbti_t = res.data.mbti_t
        that.mbti_f = res.data.mbti_f
        that.mbti_j = res.data.mbti_j
        that.mbti_p = res.data.mbti_p
        that.mbti_tz = res.data.mbti_tz
        that.mbti_aq = res.data.mbti_aq

        queryMajorDone({}).then(res => {
          if (res.rescode == 200) {
            this.questionaireDone = res.cur_q > 133 || res.cur_q === -1
          }
        })
      }
    })
  }
}
</script>

<style lang="less" scoped>
.mbti-describe {
  text-align: left;
}
@import url('./../../../../Public/resources/css/templates/home/major/std_mobile_result.min.css');
</style>
